import React from 'react';
import styles from '../scripts/styles/about.module.css'
import { CSSTransitionGroup } from "react-transition-group";
import { MainNavBar } from "./MainNavBar";
import { Footer } from "./Footer";

export const About = () => {
      return (
          <div className={styles.faceWrapper}>
             <MainNavBar/>
             <div>
                <Footer />
             </div>
          </div>
      );
}
