import React from 'react';
import styles from '../scripts/styles/faces.module.css'
import work_styles from '../scripts/styles/work.module.css'
import { TransitionGroup } from 'react-transition-group';
import { MainNavBar } from './MainNavBar';
import { Footer } from './Footer';

class Work extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      iframeLoadedAmount: 0
    };
  }

  render() {
      return (
          <div className={styles.faceWrapper}>
             <MainNavBar/>
             <div>
                <Footer />
             </div>
          </div>
      );
  }
}

Work.propTypes = {};

export default Work;
