import React from 'react';
import styles from '../scripts/styles/landing.module.css';
import { Social } from './Social';
import { NavLink } from 'react-router-dom';
import { TransitionGroup } from 'react-transition-group';

const bg = { uri: process.env.PUBLIC_URL + '/img/background.png' };

export const Landing = () => {
  return (
    <TransitionGroup
      transitionName='transition'
      transitionAppear={true}
      transitionAppearTimeout={500}
      transitionEnterTimeout={500}
      transitionLeaveTimeout={300}
      >
        <div className={styles.landingWrapper} >
          <div className={styles.navBarWrapper}>
            <nav className={styles.navBar}>
              <ul>
                <li><NavLink to="/about"
                     className={styles.btn}
                >ABOUT</NavLink></li>

                <li><NavLink to="/work"
                     className={styles.btn}
                >WORK</NavLink></li>

                <li><NavLink to='/publications'
                     className={styles.btn}
                >PUBLICATIONS</NavLink></li>

                <li><a
                     className={styles.btn}
                     href="https://dblp.org/pid/67/4673.html"
                >DBLP</a></li>
              </ul>
            </nav>
          </div>
          <div className={styles.socialWrapper}>
            <Social/>
          </div>
        </div>
    </TransitionGroup>
  );
}
