import React from 'react';

import styles from '../scripts/styles/navbar.module.css'
import { Social } from './Social';
import { NavLink } from 'react-router-dom'
import { TransitionGroup } from 'react-transition-group';

export const MainNavBar = () => {
    return (
        <TransitionGroup
            transitionName='transition'
            transitionAppear={true}
            transitionAppearTimeout={500}
            transitionEnterTimeout={500}
            transitionLeaveTimeout={300}>
            <div className={styles.navBarWrapper}>
              <div className={styles.socialWrapperSmall}>
                <Social/>
              </div>
              <nav className={styles.navBar}>
                <ul>
                    <li key={0}><NavLink exact to="/"
                                         className={styles.btn}
                    >HOME</NavLink></li>

                    <li key={1}><NavLink exact to="/about"
                                         className={styles.btn}
                                         activeStyle={{"letterSpacing": "5px", "color": "white"}}
                    >ABOUT</NavLink></li>

                    <li key={2}><NavLink exact to="/work"
                                         className={styles.btn}
                                         activeStyle={{"letterSpacing": "5px", "color": "white"}}
                    >WORK</NavLink></li>

                    <li key={3}><NavLink to="/publications"
                                         className={styles.btn}
                                         activeStyle={{"letterSpacing": "5px", "color": "white"}}
                    >PUBLICATIONS</NavLink></li>

                    <li key={'beats'}><a
                        className={styles.btn}
                        href="https://dblp.org/pid/67/4673.html"
                    >DBLP</a></li>
                </ul>
              </nav>
            </div>
        </TransitionGroup>
    );
}
