
import React from 'react';
import styles from '../scripts/styles/background.module.css'

export const BackgroundImage = () => {
    const backgroundImg = process.env.PUBLIC_URL + '/img/background.png'
    return (
        <div className={styles.imageContainer}>
                <img src={backgroundImg}/>
        </div>
    );
}
