import './App.css';
import React from 'react';
import { HashRouter as Router, Route, Switch } from "react-router-dom";
import { Landing } from './components/Landing';
import { About } from './components/About';
import Work from './components/Work';
import Publications from './components/Publications';
import {BackgroundImage} from "./components/BackgroundImage";


class App extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
  }
  render() {
      return (
        <Router>
        <BackgroundImage />
          <Switch location={this.props.location}>
            <Route exact path="/" component={Landing} />
            <Route exact path="/about" component={About} />
            <Route exact path="/work" component={Work} />
            <Route exact path="/publications" component={Publications} />
          </Switch>
        </Router>
      );
  }
}

export default App;
