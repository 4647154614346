import React from 'react';
import classes from '../scripts/styles/footer.module.css'

export const Footer = () => {
    return (
        <div className={classes.footerWrapper}>
            <span>Copyright &copy; 2021 www.sapkota.org | bn@sapkota.org</span>
        </div>
    );
};
