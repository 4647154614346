import React from 'react'

export const Social = () => {
  let socials = [
    {href:'https://inkedin.com/in/bnsapkota', img:'/img/social/LI-In-Bug.png'},
    {href:'https://twitter.com/bnsapkota', img:'/img/social/twitter.png'}
  ]

  return (
    socials.map((value, index) => {
      return <a href={socials[index].href} key={socials[index].href}><img
        src={process.env.PUBLIC_URL + socials[index].img}
        alt={socials[index].img}/></a>
    })
  );
};
